.filterList {
  list-style: none;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
}

.filterList li {
  border-radius: 30px;
  padding: 0.5rem 1rem;
  background: var(--cor-5);
  color: var(--cor-4);
  width: auto;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.filterList li button {
  display: grid;
  place-items: center;
  margin-left: 0.5rem;
}

.filterList li svg {
  width: 1.2rem;
  height: 1.2rem;
}

.searchName {
  margin-top: 2rem;
  color: var(--cor-4);
  display: flex;
  align-items: center;
}

.darkMode {
  color: var(--cor-5);
}

.searchName button {
  display: grid;
  place-items: center;
  margin-right: 0.5rem;
}

.searchName button svg {
  width: 1.2rem;
  height: 1.2rem;
}
