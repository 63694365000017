@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap");
/* CSS Consentiment Banner */
@import "./consentiment.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --cor-1: #f0f6f4;
  --cor-2: #9d9d9d;
  --cor-3: #a4b0ac;
  --cor-4: #f4f4f4;
  --cor-5: #5b5b5b;
  --cor-6: #545463;
  --cor-7: #dae5e1a1;
  --cor-bg: #e9efed;
  --sombra: 0px 4px 30px #2324240d;
  --border: 2px solid var(--cor-7);
  scrollbar-color: #b4b4b4 var(--cor-1);
  scrollbar-width: thin;
}

.darkMode {
  --cor-1: #292f31;
  --cor-2: #c5c5c5 ;
  --cor-4: #000000;
  --cor-3: #485155;
  --cor-5: #f7f7f7;
  --cor-6: #b9bfc6;
  --cor-7: #2f3639;
  --cor-bg: #212628;
  --sombra: 0px 4px 30px #2324245f;
  --border: 2px solid var(--cor-7);
}
.app {
  background: var(--cor-bg);
  color: var(--cor-6);
}

body {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}

input {
  border: none;
  outline: none;
  background: var(--cor-bg);
  border: var(--border);
  padding: 0.2rem;
  border-radius: 8px;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

button,
input,
a {
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e9efed;
}

::-webkit-scrollbar-thumb {
  background: #a4b0ac;
}
@media all and (max-width: 550px) {
  body {
    font-size: 12px;
  }
}
