.divPaginas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

@media all and (max-width: 600px) {
  .divPaginas{
    flex-direction: column;
  }
}
