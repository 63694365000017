
.imgDiv{
  width: 100%;
  margin-bottom: -4px;
}
.imgDiv img{
  width: 100%;
  object-fit: cover;
  max-height: 100dvh;
  border-radius: 10px;
}