.item {
  display: grid;
  grid-template-columns: 7rem 1fr auto;
  grid-template-rows: 7rem;
  grid-gap: 1rem;
  padding: 1.5rem 0;
}

.item + .item {
  border-top: 2px solid var(--cor-bg);
}

.flagImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  padding: 0.2rem 0.5rem;
  background: var(--cor-1);
  box-shadow: var(--sombra);
}

.imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infos {
  display: flex;
  flex-direction: column;
}

.installmentQtd {
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.listVariable {
  display: flex;
  place-items: center;
}
.itemVariable {
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--cor-5);
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: 700;
  color: var(--cor-4);
  transition: 0.1s;
}
.price {
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 0.3rem;
}
.amountForm {
  text-align: right;
  display: flex;
  place-items: center;
}

.amountForm input {
  width: 2rem;
  height: 1.5rem;
  margin-left: 0.2rem;
  text-align: center;
}

.amountForm input[type="number"]::-webkit-inner-spin-button,
.amountForm input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.amountForm label > span {
  visibility: hidden;
  position: absolute;
}
.btnVariable,
.btnAddRemove {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--cor-5);
  margin-left: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: var(--cor-4);
  transition: 0.1s;
}

.btnAddRemove:hover {
  background: var(--cor-2);
}

.btnAddRemove:active {
  transform: scale(0.9);
}
.amount{
  padding:0 .5rem;
  background: var(--cor-5);
  color: var(--cor-4);
}
.btn {
  margin-top: auto;
  align-self: flex-start;
  padding: 0.2rem 0;
}

.item form input {
  max-width: 3rem;
  margin-left: 0.5rem;
}

@media all and (max-width: 470px) {
  .item {
    grid-template-columns: 3rem 1fr;
    grid-template-rows: 9rem;
    padding: 1rem 0;
    grid-gap: 0.8rem;
  }

  form {
    grid-column: 2;
  }

  .imgDiv img {
    width: 3rem;
    height: 3rem;
  }

  .item form input {
    max-width: 2.5rem;
  }

  .installmentQtd {
    font-size: 0.8rem;
  }
  .price {
    font-size: 1.1rem;
  }
  .amountForm input {
    width: 2rem;
    height: 1.8rem;
  }
}
