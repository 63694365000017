.filter {
  background: var(--cor-1);
  padding: 2rem;
  min-height: 100%;
  border-radius: 10px;
  box-shadow: var(--sombra);
}

.filterContainer {
  grid-row: 2;
  grid-column: 1;
  width: 253px;
}

.filter h2 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.btn {
  width: 100%;
  margin: 2rem auto;
}

.btnClear {
  margin: 0 auto;
  display: block;
}

.btnOpenFilter {
  display: none;
}

.btnClose {
  display: none;
}

@media all and (max-width: 925px) {
  .filter {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 999;
    padding: 3rem 3.5rem;
    height: 100vh;
    overflow: auto;
    visibility: hidden;
    opacity: 0.5;
    transition: 0.5s;
  }
  .filter.filterVisible {
    visibility: visible;
    opacity: 1;
    left: 0;
  }
  .filterContainer {
    grid-row: 1;
    position: absolute;
    right: 2rem;
    max-width: max-content;
  }
  
  .btnOpenFilter {
    display: flex;
    place-items: center;
    gap: 0.3rem;
    width: 3.5rem;
    height: 2.5rem;
    border-radius: 0.2rem;
    transition: 0.1s;
  }

  .btnOpenFilter.filterVisible {
    border: 2px solid var(--cor-2);
    background: var(--cor-7);
  }

  .btnOpenFilter svg {
    transform: scale(1.3);
  }

  .btn {
    width: 100%;
  }

  .btnClose {
    display: grid;
    width: 2rem;
    height: 2rem;
    top: 1.5rem;
    right: 1rem;
  }
}
@media all and (max-width: 600px) {
  .filterContainer {
    right: 1rem;
  }
}
@media all and (max-width: 470px) {
  .filter {
    width: 100%;
  }
}
