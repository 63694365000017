#gdprModal #gdprOkBtnStep1,
#gdprModal #gdprOkBtnStep2 {
  background-color: var(--cor-5);
  color: var(--cor-4);
  box-shadow: var(--sombra);
  border-radius: 8px;
}

#gdprModal #gdprControlBtn {
  background-color: var(--cor-7);
  color: var(--cor-6);
  border-radius: 8px;
}

#gdprModal #gdprOkBtnStep1:hover,
#gdprModal #gdprOkBtnStep2:hover {
  background: var(--cor-2);
}

#gdprModal #gdprControlBtn:hover{
  background: var(--cor-2);
}