.products {
  grid-row: 1 / 4;
  grid-column: 2;
}

.products h1 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.productsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: start;
  grid-gap: 2rem;
  list-style: none;
  margin: 2rem 0;
}

.error {
  margin-top: 2rem;
}

@media all and (max-width: 925px) {
  .products {
    grid-column: 1;
    grid-row: 2;
  }

  .productsList {
    grid-gap: 1rem;
  }
}

@media all and (max-width: 600px) {
  .productsList {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5rem;
  }
}
