.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--sombra);
  background: var(--cor-1);
  padding: 1.2rem;
}

.imgContainer {
  width: 100%;
  height: 12rem;
}

.card .imgContainer .svg,
.card .image {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.card .imgContainer {
  position: relative;
}
.card .imgContainer .flagImage {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1rem;
  right: 0;
  padding: 0.2rem 0.5rem;
  background: var(--cor-1);
  box-shadow: var(--sombra);
}
.card .imgContainer .flagImage svg {
  margin-right: 0.3rem;
}
.card .imgContainer .image {
  cursor: pointer;
  transition: transform 0.3s ease; /* Efeito de zoom na imagem */
}
.card .imgContainer .maximizeIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  font-weight: 700;
  color: var(--cor-5);
  padding: 10px;
  transform: translate(-50%, -50%) scale(0); /* Ícone inicialmente reduzido */
  opacity: 0; /* Invisível inicialmente */
  display: none; /* Inicialmente oculto */
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cor-4);
    opacity: 0.7; /* Define a opacidade */
    border-radius: 5px; /* Mesma borda arredondada */
    z-index: -1; /* Mantém o fundo atrás do texto */
  }
  &:after {
    content: "Click Para Vizualizar";
    font-size: 0.8rem;
    font-weight: 700;
    margin-left: 0.5rem;
  }
}
.card .imgContainer:hover .image {
  transform: scale(1.01);
}
.card .imgContainer:hover .maximizeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.015);
}

.infos {
  margin-top: 1rem;
}

.name {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  height: 35px;
}

.installmentQtd {
  font-weight: bold;
  font-size: 0.9rem;
  margin-left: 0.2rem;
}

.price {
  font-weight: bold;
  font-size: 20px;
  margin-left: 0.2rem;
}

.amountForm {
  text-align: right;
  display: flex;
  place-items: center;
}

.amountForm input {
  width: 2rem;
  height: 1.5rem;
  margin-left: 0.2rem;
  text-align: center;
}

.amountForm input[type="number"]::-webkit-inner-spin-button,
.amountForm input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.amountForm label > span {
  visibility: hidden;
  position: absolute;
}
.btnVariable,
.btnAddRemove {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--cor-5);
  margin-left: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: var(--cor-4);
  transition: 0.1s;
}

.btnAddRemove:hover {
  background: var(--cor-2);
}

.btnAddRemove:active {
  transform: scale(0.9);
}
.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1.5rem;
}
.variableContent {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  gap: 0.35rem;
  margin-top: 1.5rem;
}

.variable {
  display: flex;
  place-items: center;
  justify-content: center;
  gap: 0.35rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.btnVariable {
  font-size: 14px;
  margin-left: 0;
}
.selected {
  background: var(--cor-2);
}

.btnAddToCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  color: var(--cor-4);
  background: var(--cor-5);
  box-shadow: var(--sombra);
  text-transform: uppercase;
  transition: 0.1s;
}
.btnAddToCart:hover {
  background: var(--cor-2);
}
.btnAddToCart:active {
  transform: scale(0.9);
}
.btnAddToCart svg {
  transform: scale(1.3);
  width: 30px;
  margin-right: 0.2rem;
}

.outOfStock {
  display: grid;
  place-items: center;
  font-size: 1rem;
  height: 5rem;
  font-weight: 700;
  border-top: var(--border);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}

@keyframes animaAlert {
  from {
    top: -2rem;
    opacity: 1;
  }

  to {
    top: -5rem;
    opacity: 0;
  }
}

@media all and (max-width: 925px) {
  .card .imgContainer .maximizeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none;
    height: auto;
    top: auto;
    left: 0;
    bottom: 0;
    opacity: 0.8;
    padding: 0;
    font-size: 1rem;
    background-color: var(--cor-4);
  }
  .card .imgContainer:hover .maximizeIcon {
    display: flex;
    opacity: 1;
    transform: none;
  }
  .card .imgContainer:hover .image {
    transform: none;
  }
}

@media all and (max-width: 600px) {
  .btnAdd {
    width: 2.2rem;
    height: 2.2rem;
  }
  .amountForm input {
    width: 2.5rem;
    height: 2.2rem;
  }
  .installmentQtd {
    font-size: 1rem;
  }
  .price {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .card {
    padding: 1rem;
  }
}

@media all and (max-width: 480px) {
  .btnAdd {
    width: 2rem;
    height: 2rem;
    margin-left: 0.2rem;
  }
  .amountForm input {
    width: 2rem;
    height: 1.8rem;
  }
  .imgContainer {
    height: 225px;
  }
  .card {
    padding: 0.5rem;
  }
}
