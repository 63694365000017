.form input {
  width: 4rem;
  margin-left: auto;
}

.form label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1rem;
  width: 100%;
}
