.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--cor-1);
  box-shadow: var(--sombra);
  padding: 1.2rem 1rem;
  height: 4rem;
  position: relative;
  z-index: 2;
  margin-top: 3rem;
  gap:12px;
}

.footer p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.2rem;
}

.footer a:hover {
  text-decoration: underline;
}

.footer a svg {
  width: 2rem;
}
