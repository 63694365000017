.header {
  background: var(--cor-1);
  position: fixed;
  left: 2rem;
  top: 1rem;
  z-index: 999;
  width: calc(100% - 4rem);
  border-radius: 10px;
  box-shadow: var(--sombra);
  transition: 0.2s;
}
.header.scroll {
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 0;
}

.header .container {
  display: flex;
  align-items: center;
  height: 5.5rem;
  padding: 1.2rem 2rem;
}

.logo {
  font-size: 1.6rem;
  margin-right: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.logo svg {
  height: 100%;
  width: 100%;
}

.cart {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  transition: 0.1s;
  text-align: right;
  font-weight: 700;
  margin-left: auto;
  margin-right: 1rem;
  text-transform: uppercase;
  font-size: 12px;
}

.cart:hover,
.conta:hover {
  color: var(--cor-2);
}

.cart svg {
  transform: scale(1.3);
  width: 30px;
  margin-left: 0.5rem;
}

.amount {
  position: relative;
  display: grid;
  place-items: center;
}

.value {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: -1rem;
  top: -1rem;
  background: var(--cor-5);
  color: var(--cor-4);
  font-weight: bold;
  font-size: 0.75rem;
  display: grid;
  place-items: center;
}

.menu {
  margin-left: auto;
}

.btnMenu {
  display: none;
}

.btnColorMode {
  background: var(--cor-7);
  min-width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  display: grid;
  place-items: center;
  color: var(--cor-6);
}

@media all and (max-width: 925px) {
  .header {
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 0;
  }
  .cartTxt {
    visibility: hidden;
    position: absolute;
  }
  .logo {
    font-size: 1.2rem;
    margin-right: 1rem;
    flex-wrap: nowrap;
  }
}
@media all and (max-width: 600px) {
  .header .container {
    padding: 1.2rem 1rem;
  }
}

@media all and (max-width: 480px) {
  .header .container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 4.5rem 3rem;
    padding: 0.5rem 1rem;
  }
  .logo,
  .cart,
  .btnColorMode {
    grid-row: 1;
  }

  .cart {
    margin-left: initial;
  }
  .btnColorMode {
    margin-left: auto;
  }
}
