.btnColor {
  border-radius: 0.2rem;
  text-transform: capitalize;
  min-width: 1.8rem;
  min-height: 1.8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.btnColor span {
  position: absolute;
  visibility: hidden;
}
.listColors {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0.7rem;
}

.colorItem {
  position: relative;
}

.colorItem label {
  display: block;
  width: 100%;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: 0.03s;
  cursor: pointer;
  width:29px;
  height:29px;
}

.colorItem label:hover {
  transform: scale(1.05);
}

.checked label {
  box-shadow: 0 0 0 2px var(--cor-1), 0 0 0 3px #adadb1;
}

.colorItem input {
  position: absolute;
  z-index: -99;
  visibility: hidden;
}
