.modal {
  padding: 2rem;
  text-align: center;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.paragrafe{
  line-height:1.5;
}
.button {
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto auto;
  padding: 0.7rem;
}

.button span{
  margin-left: .5rem;
}
