.main {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: start;
  min-height: calc(100vh - 4rem);
  padding: 8rem 2rem 0 2rem;
  grid-gap: 3rem;
}

.navCatalogo {
  grid-column: 1;
  grid-row: 1;
  height: 2rem;
  line-height: 2rem;
  margin-bottom: -1rem;
}

@media all and (max-width: 925px) {
  .main {
    grid-template-columns: auto;
    grid-gap: 1rem;
    padding: 7rem 2rem 0 2rem;
  }

  .navCatalogo {
    height: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0;
  }
}

@media all and (max-width: 600px) {
  .main {
    padding: 6rem 1rem 0 1rem;
  }
}

@media all and (max-width: 480px) {
  .main {
    padding: 35% 1rem 0 1rem;
  }
}
