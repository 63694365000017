.modal {
  position: fixed;
  z-index: 10000;
  display: grid;
  place-items: center;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 650px;
  width: 100%;
  padding: 0 1rem;
}

.content {
  background: var(--cor-1);
  color: var(--cor-6);
  transform: translateY(-1rem);
  animation: 0.1s show forwards;
  border-radius: 10px;
  box-shadow: var(--sombra);
  width: 100%;
}

@keyframes show {
  to {
    transform: translateY(0);
  }
}

.backdrop {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
